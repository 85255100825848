
import Image from 'next/image';

import * as Style from './style';

export interface IMainImageProps {
  image?: IMainImage;
  children?: JSX.Element;
  isHomepage: boolean;
}

export interface IMainImage {
  id: number;
  title: string;
  large: string;
  medium: string;
  small: string;
}

const MainImage = ({ image, children, isHomepage}: IMainImageProps) => {
  return (
    <Style.TopImage isHomepage={isHomepage as boolean}>
      <picture>
        <Image src={image?.large as string} alt={image?.title as string} width={1200} height={800} priority />
      </picture>
      {children}
    </Style.TopImage>
  );
};

export default MainImage;
